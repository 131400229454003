




















































































import { Component, Vue, Watch } from "vue-property-decorator";
import ResultBlockView from "./components/ResultBlockView.vue";

import DateTime from "@/view/content/DateTime.vue";
import { PatientVm } from "@/api/api";
import yearsHelper from "@/services/helper";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/ru";

import { profileModule, resultsModule } from "@/store";

@Component({
  components: {
    DatePicker,
    DateTime,
    ResultBlockView,
  },
})
export default class Home extends Vue {
  laboratory = null;
  getYears = yearsHelper.getYears;

  selectPatient(patient: PatientVm) {
    profileModule.mutations.setPatient(patient);
  }

  setDateRange(range: string[]) {
    resultsModule.mutations.setFilterDateRange(range);
  }

  get filter(): { dateRange: string[] | null } {
    return resultsModule.state.filter;
  }

  get patientResultsLoading() {
    return resultsModule.state.listLoading;
  }

  get patients(): PatientVm[] {
    return profileModule.state.patients;
  }

  get patient(): PatientVm | null {
    return profileModule.state.patient;
  }

  get orders() {
    return resultsModule.getters.resultList;
  }

  mounted() {
    if (this.patient == null) return;
    resultsModule.actions.loadPatientResultList(this.patient!.patientId!);
  }

  @Watch("patient")
  patientChanged(): void {
    if (this.patient == null) return;
    resultsModule.actions.loadPatientResultList(this.patient!.patientId!);
  }
}
