var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":'plotcontainer' + _vm.bidResult.current.bidValueId}},[_c('div',{staticClass:"mb-6 d-flex justify-content-start"},[_c('div',{staticClass:"bid-indicator font-weight-bolder text-dark-75 font-size-lg"},[_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.index + 1)+".")]),_vm._v(" "+_vm._s(_vm.bidResult.current.indicatorName)+" "),(_vm.studyName && _vm.studyName.length > 1)?_c('div',{staticStyle:{"width":"100%","line-height":"1"}},[_c('small',{staticClass:"text-muted"},[_vm._v("Услуга: "+_vm._s(_vm.studyName))])]):_vm._e()]),_c('div',{staticClass:"ml-2"},[(
          !_vm.hasAvailableDimensions ||
          (_vm.bidResult.current.type != _vm.StudyIndicatorFieldType.Number &&
            _vm.bidResult.current.type != _vm.StudyIndicatorFieldType.Calculate)
        )?[_vm._v(" "+_vm._s(_vm.bidResult.current.dimension)+" ")]:[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedDimension),expression:"selectedDimension"}],staticClass:"dimension-selector",class:{
            'text-warning': _vm.hasAvailableDimensions,
            'text-muted': !_vm.hasAvailableDimensions,
          },staticStyle:{"font-size":"14px"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedDimension=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.bidResult.current.availableDimensions),function(dimension){return _c('option',{key:dimension.name,staticClass:"text-dark",domProps:{"value":dimension}},[_vm._v(" "+_vm._s(dimension.name)+" ")])}),0)]],2)]),(_vm.isDrawPlotAvailable)?[_c('canvas',{directives:[{name:"show",rawName:"v-show",value:(_vm.normMin != null || _vm.normMax != null),expression:"normMin != null || normMax != null"}],key:_vm.bidResult.current.bidValueId,ref:"plot",attrs:{"id":'plot' + _vm.bidResult.current.bidValueId,"width":_vm.width,"height":_vm.height}})]:[_c('div',{staticStyle:{"display":"flex"}},[(_vm.hasPrevValue)?_c('div',{staticClass:"bidvalue"},[_c('div',{staticClass:"bidvalue-block m-r-xs",class:{
            success:
              _vm.bidResult.prev.status === _vm.NormStatus.Ok ||
              _vm.bidResult.prev.status === _vm.NormStatus.none,
            danger:
              _vm.bidResult.prev.status !== _vm.NormStatus.Ok &&
              _vm.bidResult.prev.status !== _vm.NormStatus.None,
          }},[_vm._v(" "+_vm._s(_vm.prevValue)+" ")]),_c('small',[_c('date-time',{attrs:{"datetimestring":_vm.bidResult.prev.dateTime}})],1)]):_vm._e(),_c('div',{staticClass:"bidvalue",class:{ noprev: !_vm.hasPrevValue }},[_c('div',{staticClass:"bidvalue-block",class:{
            'm-r-xs': _vm.hasPrevValue,
            success:
              _vm.bidResult.current.status === _vm.NormStatus.Ok ||
              _vm.bidResult.current.status === _vm.NormStatus.None,
            danger:
              _vm.bidResult.current.status !== _vm.NormStatus.Ok &&
              _vm.bidResult.current.status !== _vm.NormStatus.None,
          }},[_vm._v(" "+_vm._s(_vm.currentValue)+" ")]),_c('small',[_c('date-time',{attrs:{"datetimestring":_vm.bidResult.current.dateTime}})],1)])])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }