




































































































import { Component, Watch, Vue, Prop } from "vue-property-decorator";
import {
  BidResultVm,
  StudyIndicatorFieldType,
  NormStatus,
  AvailableDimension,
} from "@/api/api";
import dayjs from "dayjs";
import DateTime from "@/view/content/DateTime.vue";

@Component({
  components: {
    DateTime,
  },
})
export default class BidResultPlotsView extends Vue {
  @Prop({ required: true })
  bidResult!: BidResultVm;

  @Prop({ required: true })
  studyName!: string;

  @Prop({ required: true })
  index!: number;

  StudyIndicatorFieldType = StudyIndicatorFieldType;
  NormStatus = NormStatus;
  selectedDimension: AvailableDimension | null | undefined = null;

  width = 300;
  height = 120;
  axisHeight = 10;
  widthCoefficient = 1.4;
  offsetY = this.height * 0.5;
  offsetX = 0;
  dimensionConvertCoefficient = 1;
  offsetAxis = 0;

  resizeTimer = 0;

  normMin: number | null = null;
  normMax: number | null = null;
  current: number | null = null;
  prev: number | null | undefined = null;
  maxValue: number | null = null;
  minValue: number | null = null;
  coefficient: number | null = null;

  RED = "#D81E05";
  YELLOW = "#d8aa19";
  GREEN = "#2AD62A";

  clearPlot(ctx: CanvasRenderingContext2D) {
    ctx.clearRect(0, 0, this.width, this.height);
  }

  private convertValueToPositionX(val: number) {
    return (
      this.offsetX +
      (val - this.minValue! + this.offsetAxis) * this.coefficient!
    );
  }

  get isDrawPlotAvailable() {
    return (
      (this.bidResult.current?.normMin != null ||
        this.bidResult.current?.normMax) &&
      (this.bidResult.current.type == StudyIndicatorFieldType.Number ||
        this.bidResult.current.type == StudyIndicatorFieldType.Calculate)
    );
  }

  drawAxisLabel(
    ctx: CanvasRenderingContext2D,
    positionX: number,
    value: string,
    offset?: number,
    drawAxisLine = true
  ): void {
    if (this.coefficient == null) return;
    ctx.font = "12px Verdana";
    ctx.fillStyle = "#D81E05";
    let xValuePosition =
      positionX - ctx.measureText(value).width / 2 + (offset || 0);
    if (xValuePosition < 0) xValuePosition = 0;
    ctx.fillText(value, xValuePosition, this.offsetY + 25);

    if (drawAxisLine) {
      ctx.beginPath();
      ctx.moveTo(positionX, this.offsetY);
      ctx.lineTo(positionX, this.offsetY + 15);
      ctx.closePath();
      ctx.strokeStyle = "#d6d6d6";
      ctx.stroke();
    }
  }

  drawAxis(ctx: CanvasRenderingContext2D) {
    if (this.maxValue == null || this.coefficient == null) {
      return;
    }
    const gradient = ctx.createLinearGradient(0, 0, this.width, 0);
    if (this.normMin != null && this.normMax != null) {
      gradient.addColorStop(0, this.RED);
      gradient.addColorStop(
        this.convertValueToPositionX(this.normMin) / this.width,
        this.YELLOW
      );
      gradient.addColorStop(
        this.convertValueToPositionX((this.normMin + this.normMax) / 2) /
          this.width,
        this.GREEN
      );
      gradient.addColorStop(
        this.convertValueToPositionX(this.normMax) / this.width,
        this.YELLOW
      );
      gradient.addColorStop(1, this.RED);
    } else if (this.normMin != null) {
      gradient.addColorStop(0, this.RED);
      gradient.addColorStop(
        this.convertValueToPositionX(this.normMin) / this.width,
        this.YELLOW
      );
      gradient.addColorStop(1, this.GREEN);
    } else if (this.normMax != null) {
      gradient.addColorStop(0, this.GREEN);
      gradient.addColorStop(
        this.convertValueToPositionX(this.normMax) / this.width,
        this.YELLOW
      );
      gradient.addColorStop(1, this.RED);
    }
    ctx.fillStyle = gradient;
    ctx.fillRect(this.offsetX + 0, this.offsetY, this.width, this.axisHeight);

    ctx.globalCompositeOperation = "source-over";
    if (this.normMin != undefined && this.normMin != this.normMax) {
      let drawAxisLine = true;
      const minNormPositionX = this.convertValueToPositionX(this.normMin);
      const minNormMaxWidth = Math.max(
        ctx.measureText("").width,
        ctx.measureText(this.normMin.toString()).width
      );
      if (this.normMax != undefined) {
        const xPosition = this.convertValueToPositionX(this.normMax);
        const maxNormMaxWidth = Math.max(
          ctx.measureText("").width,
          ctx.measureText(this.normMax.toString()).width
        );
        const labelRightXPosition = minNormPositionX + minNormMaxWidth / 2 + 5;
        if (xPosition - maxNormMaxWidth / 2 <= labelRightXPosition) {
          drawAxisLine = false;
          const offset =
            labelRightXPosition - (xPosition - maxNormMaxWidth / 2) + 5;
          this.drawAxisLabel(
            ctx,
            xPosition,
            this.normMax.toString(),
            offset,
            drawAxisLine
          );
        } else {
          this.drawAxisLabel(ctx, xPosition, this.normMax.toString());
        }
      }

      this.drawAxisLabel(
        ctx,
        minNormPositionX,
        this.normMin.toString(),
        0,
        drawAxisLine
      );
    } else if (this.normMax != undefined) {
      this.drawAxisLabel(
        ctx,
        this.convertValueToPositionX(this.normMax),
        this.normMax.toString()
      );
    }
  }

  drawCurrentValue(
    ctx: CanvasRenderingContext2D,
    val: number,
    datetime: string,
    textColor: string
  ) {
    if (this.maxValue == null || this.coefficient == null) {
      return;
    }
    ctx.beginPath();
    ctx.moveTo(this.convertValueToPositionX(val), this.offsetY - 20);
    ctx.lineTo(this.convertValueToPositionX(val), this.offsetY + 10);
    ctx.closePath();
    ctx.strokeStyle = "#d6d6d6";
    ctx.stroke();

    ctx.font = "24px Verdana";
    const valWidth = ctx.measureText(val.toString()).width;
    let xPosition = this.convertValueToPositionX(val) - valWidth / 2;
    if (xPosition < 0) xPosition = 0;

    ctx.fillStyle = textColor;
    ctx.fillText(val.toString(), xPosition, this.offsetY - 34);
    ctx.font = "8px Verdana";
    ctx.fillText(datetime, xPosition, this.offsetY - 22);
  }

  isHoverNorm(
    ctx: CanvasRenderingContext2D,
    pervValuePositionX: number,
    valWidth: number
  ) {
    if (this.normMin != null) {
      const width = ctx.measureText(this.normMin.toString()).width;
      let xMinNormPosition =
        this.convertValueToPositionX(this.normMin) - width / 2;
      if (xMinNormPosition < 0) xMinNormPosition = 0;
      if (
        (pervValuePositionX >= xMinNormPosition &&
          pervValuePositionX <= xMinNormPosition + width) ||
        (pervValuePositionX + valWidth >= xMinNormPosition &&
          pervValuePositionX + valWidth <= xMinNormPosition + width) ||
        (xMinNormPosition >= pervValuePositionX &&
          xMinNormPosition <= pervValuePositionX + valWidth)
      )
        return true;
    }
    if (this.normMax != null) {
      const width = ctx.measureText(this.normMax.toString()).width;
      let xMaxNormPosition =
        this.convertValueToPositionX(this.normMax) - width / 2;
      if (xMaxNormPosition < 0) xMaxNormPosition = 0;
      if (
        (pervValuePositionX >= xMaxNormPosition &&
          pervValuePositionX <= xMaxNormPosition + width) ||
        (pervValuePositionX + valWidth >= xMaxNormPosition &&
          pervValuePositionX + valWidth <= xMaxNormPosition + width) ||
        (xMaxNormPosition >= pervValuePositionX &&
          xMaxNormPosition <= pervValuePositionX + valWidth)
      )
        return true;
    }
    return false;
  }

  drawPrevValue(
    ctx: CanvasRenderingContext2D,
    val: number,
    datetime: string,
    textColor: string
  ) {
    if (this.maxValue == null || this.coefficient == null) {
      return;
    }
    ctx.beginPath();
    ctx.moveTo(this.convertValueToPositionX(val), this.offsetY);
    ctx.lineTo(this.convertValueToPositionX(val), this.offsetY + 15);
    ctx.closePath();
    ctx.strokeStyle = "#d6d6d6";
    ctx.stroke();

    ctx.font = "12px Verdana";
    ctx.fillStyle = textColor;

    const valWidth = ctx.measureText(val.toString()).width;
    let xPosition = this.convertValueToPositionX(val) - valWidth / 2;
    if (xPosition < 0) xPosition = 0;

    if (this.isHoverNorm(ctx, xPosition, valWidth)) {
      ctx.fillText(`${val}`, xPosition, this.offsetY + 38);
      ctx.font = "8px Verdana";
      ctx.fillText(datetime, xPosition + valWidth + 3, this.offsetY + 38);
    } else {
      ctx.fillText(val.toString(), xPosition, this.offsetY + 25);
      ctx.font = "8px Verdana";
      ctx.fillText(datetime, xPosition, this.offsetY + 38);
    }
  }

  drawPlot(): void {
    this.width =
      document.getElementById(
        `plotcontainer${this.bidResult.current?.bidValueId}`
      )?.clientWidth || 300;
    if (this.selectedDimension != null) {
      this.dimensionConvertCoefficient = this.selectedDimension.coefficient!;
    }
    this.$nextTick(() => {
      const canvas = this.$refs.plot as HTMLCanvasElement;
      if (canvas == null) {
        console.warn("canvas not found " + this.bidResult.current?.indicatorId);
        return;
      }
      const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
      ctx.globalCompositeOperation = "destination-over";

      if (
        this.bidResult.current?.type != StudyIndicatorFieldType.Number &&
        this.bidResult.current?.type != StudyIndicatorFieldType.Calculate
      ) {
        console.warn("not number");
        return;
      }
      this.normMin =
        Math.round(
          this.bidResult.current!.normMin! *
            this.dimensionConvertCoefficient *
            100000
        ) / 100000;
      this.normMax =
        Math.round(
          this.bidResult.current!.normMax! *
            this.dimensionConvertCoefficient *
            100000
        ) / 100000;
      this.current =
        Math.round(
          this.bidResult.current!.value! *
            this.dimensionConvertCoefficient *
            100000
        ) / 100000;
      this.prev =
        Math.round(
          (this.bidResult?.prev?.value || 0) *
            this.dimensionConvertCoefficient *
            100000
        ) / 100000;

      this.maxValue = Math.max(
        this.normMin || 0,
        this.normMax || 0,
        this.current || 0,
        this.prev || 0
      );

      this.minValue = Math.min(
        this.normMin || 0,
        this.normMax || 0,
        this.current || 0,
        this.prev || 0
      );

      this.coefficient =
        this.width / (this.widthCoefficient * (this.maxValue - this.minValue));
      this.offsetAxis =
        (this.maxValue - this.minValue) * ((this.widthCoefficient - 1) / 2);
      this.clearPlot(ctx);
      this.drawCurrentValue(
        ctx,
        this.current,
        dayjs(this.bidResult.current?.dateTime).format("DD.MM.YYYY"),
        this.bidResult.current!.status === NormStatus.Ok ? this.GREEN : this.RED
      );
      if (this.prev != undefined) {
        this.drawPrevValue(
          ctx,
          this.prev,
          dayjs(this.bidResult.prev?.dateTime).format("DD.MM.YYYY"),
          "#999999"
        );
      }
      this.drawAxis(ctx);
    });
    this.$forceUpdate();
  }

  windowResize(e: any) {
    if (this.resizeTimer) {
      clearTimeout(this.resizeTimer);
    }
    this.resizeTimer = setTimeout(() => this.drawPlot(), 100);
  }

  get hasAvailableDimensions(): boolean {
    return (
      this.bidResult.current?.availableDimensions != null &&
      this.bidResult.current?.availableDimensions.length > 1
    );
  }

  get hasPrevValue(): boolean {
    return this.bidResult.prev != null;
  }

  get currentValue() {
    if (
      this.bidResult.current?.type == StudyIndicatorFieldType.Number ||
      this.bidResult.current?.type == StudyIndicatorFieldType.Calculate
    ) {
      if (
        this.hasAvailableDimensions &&
        this.selectedDimension != null &&
        this.selectedDimension?.name !== this.bidResult.current.dimension
      ) {
        return (
          this.bidResult.current!.value! * this.selectedDimension?.coefficient!
        );
      }
      return this.bidResult.current!.value;
    }
    const str =
      this.bidResult.current?.valueStr ||
      this.bidResult.current?.valueList?.join(",");
    return str;
  }

  get prevValue() {
    if (this.bidResult.prev == null || this.bidResult?.prev?.value == null)
      return null;
    if (
      (this.bidResult.prev.type == StudyIndicatorFieldType.Number ||
        this.bidResult.prev.type == StudyIndicatorFieldType.Calculate) &&
      this.bidResult.prev.dimension == this.bidResult.current?.dimension
    ) {
      if (
        this.hasAvailableDimensions &&
        this.selectedDimension != null &&
        this.selectedDimension?.name !== this.bidResult.current?.dimension
      ) {
        return this.bidResult.prev.value * this.selectedDimension?.coefficient!;
      }
      return this.bidResult.prev?.value;
    }
    const str =
      this.bidResult.prev?.valueStr ||
      this.bidResult.prev?.valueList?.join(",");
    return str;
  }

  created() {
    window.addEventListener("resize", this.windowResize);
  }

  destroyed() {
    window.removeEventListener("resize", this.windowResize);
  }

  mounted() {
    this.selectedDimension = this.bidResult.current?.availableDimensions?.find(
      (d) => d.name === this.bidResult.current?.dimension
    );
    setTimeout(() => this.drawPlot(), 100);
  }

  @Watch("bidResult")
  onBidResultChanged() {
    setTimeout(() => this.drawPlot(), 100);
  }

  @Watch("selectedDimension")
  onDimensionChanged() {
    setTimeout(() => this.drawPlot(), 100);
  }
}
