






























































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import DateTime from "@/view/content/DateTime.vue";
import BidsResultTableView from "./BidsResultTableView.vue";
import BidsResultSlideView from "./BidsResultSlideView.vue";
import {
  BidClient,
  BidStatuses,
  FileResponse,
  PatientServiceRequestVm,
  PatientVm,
} from "@/api/api";
import AuthService from "@/services/auth.service";

import Swal from "sweetalert2";
import { authModule, profileModule, resultsModule } from "@/store";
import dayjs from "dayjs";

@Component({
  components: {
    DateTime,
    BidsResultTableView,
    BidsResultSlideView,
  },
})
export default class ResultBlockView extends Vue {
  @Prop({ required: true })
  order!: PatientServiceRequestVm;

  timestamp = Date.now().toString();
  showSlides = false;
  loadingData = false;

  get login() {
    return authModule.state.phone;
  }

  get results() {
    const bids = resultsModule.state.results.find(
      (r) => r.key == this.order.serviceRequestId
    );
    return bids?.values || [];
  }

  get readyResults() {
    const bids = resultsModule.state.results.find(
      (r) => r.key == this.order.serviceRequestId
    );
    return bids?.values.filter(b => b.status === BidStatuses.Completed) || [];
  }

  get canShowSlides() {
    const indicatorsCount = this.results
      .map((x) => x.results?.length || 0)
      .reduce((a, b) => a + b, 0);
    return (
      indicatorsCount > 3 &&
      !this.results.some((b) =>
        b.results?.some(
          (v) => v.current?.normMin == null && v.current?.normMax == null
        )
      )
    );
  }

  get rejectedBids() {
    return this.results.filter((b) => b.status === BidStatuses.RejectedAfterSorting || b.status === BidStatuses.Rejected);
  }

  get inworkBids() {
    return this.results.filter(
      (b) =>
        b.status !== BidStatuses.Rejected &&
        b.status !== BidStatuses.RejectedAfterSorting &&
        b.status !== BidStatuses.Completed
    );
  }

  get patient(): PatientVm | null {
    return profileModule.state.patient;
  }

  get totalRreadyCount(): string {
    if (
      this.results.filter(
        (b) =>
          b.status == BidStatuses.Completed ||
          b.status == BidStatuses.Rejected ||
          b.status == BidStatuses.RejectedAfterSorting
      ).length === 0
    ) {
      const count = this.results.length;
      if (count.toString().endsWith("1")) return `${count} услуга`;
      if (
        count.toString().endsWith("2") ||
        count.toString().endsWith("3") ||
        count.toString().endsWith("4")
      )
        return `${count} услуги`;
      return `${count} услуг`;
    }
    const count = this.results.filter(
      (b) =>
        b.status == BidStatuses.Completed ||
        b.status == BidStatuses.Rejected ||
        b.status == BidStatuses.RejectedAfterSorting
    ).length;
    return `${count} из ${this.results.length} услуг`;
  }

  async downloadPdf() {
    (this as any).$metrika.reachGoal("download.pdf", {
      params: { phone: this.login },
    });
    const client = new BidClient();
    await client.setAuthToken(AuthService.refreshToken);
    client
      .download(this.order.serviceRequestId)
      .then((file: FileResponse) => {
        var url = window.URL.createObjectURL(file.data);
        var a = document.createElement("a");
        a.href = url;
        a.download = `результаты_${dayjs(this.order!.created!).format(
          "DD-MM-YYYY"
        )}.pdf`;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      });
  }

  async sendToEmail() {
    (this as any).$metrika.reachGoal("send.pdf.to.email", {
      params: { phone: this.login },
    });
    if (!this.patient!.isEmailConfirmed) {
      Swal.fire({
        text:
          "Для получения результатов на почту, необходимо указать и подтвердить Email.",
        icon: "warning",
        confirmButtonText: "Перейти в настройки",
        denyButtonText: "Закрыть",
        showDenyButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$router.push({ name: "profile" });
        }
      });
      return;
    }
    const client = new BidClient();
    await client.setAuthToken(AuthService.refreshToken);
    client
      .sendresultstoemail(
        this.order.serviceRequestId,
        this.patient!.patientId
      )
      .then(() => {
        Swal.fire({
          text: `Письмо с результатами отправлено на ${this.patient!.email}`,
          icon: "success",
        });
      });
  }

  get marked(){
    return this.results.map(x => x.marked).find(x => x != null);
  }

  setShowSlides(show: boolean) {
    this.showSlides = show;
    (this as any).$metrika.reachGoal(show ? "click.slide" : "click.table", {
      params: { phone: this.login },
    });
  }

  loadBidData(): void {
    (this as any).$metrika.reachGoal("open.result", {
      params: { phone: this.login },
    });
    this.loadingData = true;
    this.timestamp = Date.now().toString();
    resultsModule.actions
      .loadPatientResult(this.order.serviceRequestId!)
      .then(() => {
        this.loadingData = false;
      });
  }
}
