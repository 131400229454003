







































































import { Component, Vue, Prop } from "vue-property-decorator";
import BidIndicatorResultView from "./BidIndicatorResultView.vue";

import { BidVm, BidStatuses } from "@/api/api";

import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

@Component({
  components: {
    BidIndicatorResultView,
    VueSlickCarousel,
  },
})
export default class BidsResultSlideView extends Vue {
  @Prop({ required: true })
  bids!: BidVm[];

  @Prop({ required: true })
  timestamp!: string;

  get getReadyBids() {
    return this.bids?.filter((b) => b.status === BidStatuses.Completed) || [];
  }

  get moreThanOneIndicator() {
    if (this.getReadyBids.length == 0) return false;
    if (this.getReadyBids.length == 1)
      return (this.getReadyBids[0].results?.length || 0) > 1;
    return true;
  }
}
