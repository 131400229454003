



































































import { Component, Vue, Prop } from "vue-property-decorator";
import DateTime from "@/view/content/DateTime.vue";

import {
  AvailableDimension,
  BidResultVm,
  NormStatus,
  StudyIndicatorFieldType,
} from "@/api/api";

@Component({
  components: {
    DateTime,
  },
})
export default class BidResultTableRowsView extends Vue {
  @Prop({ required: true })
  value!: BidResultVm;

  selectedDimension: AvailableDimension | null | undefined = null;

  NormStatus = NormStatus;
  StudyIndicatorFieldType = StudyIndicatorFieldType;

  mounted() {
    this.selectedDimension = this.value.current?.availableDimensions?.find(
      (d) => d.name === this.value.current?.dimension
    );
  }

  get currentValue() {
    if (
      this.value.current?.type == StudyIndicatorFieldType.Number ||
      this.value.current?.type == StudyIndicatorFieldType.Calculate
    ) {
      if (
        this.hasAvailableDimensions &&
        this.selectedDimension != null &&
        this.selectedDimension?.name !== this.value.current.dimension
      ) {
        const val =
          (this.value.current?.value || 0) *
          this.selectedDimension?.coefficient!;
        return new Intl.NumberFormat("ru-RU", { maximumFractionDigits: 5 })
          .format(val)
          .replace(",", ".");
      }
      return new Intl.NumberFormat("ru-RU", { maximumFractionDigits: 5 })
        .format(this.value.current.value!)
        .replace(",", ".");
    }
    const str =
      this.value.current?.valueStr || this.value.current?.enumValues || "";
    return str.length > 13 ? `${str.substr(0, 13)}...` : str;
  }

  get prevValue() {
    if (this.value.prev == null || this.value.prev.value == null) return null;
    if (
      (this.value.prev.type == StudyIndicatorFieldType.Number ||
        this.value.prev.type == StudyIndicatorFieldType.Calculate) &&
      this.value.prev.dimension == this.value.current?.dimension
    ) {
      if (
        this.hasAvailableDimensions &&
        this.selectedDimension != null &&
        this.selectedDimension?.name !== this.value.current?.dimension
      ) {
        const val =
          this.value.prev!.value * this.selectedDimension?.coefficient!;
        return new Intl.NumberFormat("ru-RU", { maximumFractionDigits: 5 })
          .format(val)
          .replace(",", ".");
      }
      return new Intl.NumberFormat("ru-RU", { maximumFractionDigits: 5 })
        .format(this.value.prev?.value)
        .replace(",", ".");
    }
    const str = this.value.prev?.valueStr || this.value.prev?.enumValues || "";
    return str.length > 13 ? `${str.substr(0, 13)}...` : str;
  }

  get normMin() {
    if (this.value.current?.normMin == null) return null;
    if (
      this.value.current?.type == StudyIndicatorFieldType.Number ||
      this.value.current?.type == StudyIndicatorFieldType.Calculate
    ) {
      if (
        this.hasAvailableDimensions &&
        this.selectedDimension != null &&
        this.selectedDimension?.name !== this.value.current.dimension
      ) {
        const val =
          (this.value.current?.normMin || 0) *
          this.selectedDimension?.coefficient!;
        return new Intl.NumberFormat("ru-RU", { maximumFractionDigits: 5 })
          .format(val)
          .replace(",", ".");
      }
      return new Intl.NumberFormat("ru-RU", { maximumFractionDigits: 5 })
        .format(this.value.current?.normMin)
        .replace(",", ".");
    }
    return null;
  }

  get normMax() {
    if (this.value.current?.normMax == null) return null;
    if (
      this.value.current?.type == StudyIndicatorFieldType.Number ||
      this.value.current?.type == StudyIndicatorFieldType.Calculate
    ) {
      if (
        this.hasAvailableDimensions &&
        this.selectedDimension != null &&
        this.selectedDimension?.name !== this.value.current.dimension
      ) {
        const val =
          (this.value.current?.normMax || 0) *
          this.selectedDimension?.coefficient!;
        return new Intl.NumberFormat("ru-RU", { maximumFractionDigits: 5 })
          .format(val)
          .replace(",", ".");
      }
      return new Intl.NumberFormat("ru-RU", { maximumFractionDigits: 5 })
        .format(this.value.current?.normMax)
        .replace(",", ".");
    }
    return null;
  }

  get hasAvailableDimensions(): boolean {
    return (
      this.value.current?.availableDimensions != null &&
      this.value.current?.availableDimensions.length > 1
    );
  }

  get hasAlert(): boolean {
    return (
      this.value.current?.status != NormStatus.Ok &&
      this.value.current?.status != NormStatus.None
    );
  }
}
