











import { Component, Vue, Prop } from "vue-property-decorator";
import BidResultTableRowView from "./BidResultTableRowView.vue";
import { BidResultVm } from "@/api/api";

@Component({
  components: {
    BidResultTableRowView,
  },
})
export default class BidResultTableRowsView extends Vue {
  @Prop({ required: true })
  bidResults!: BidResultVm[];
}
