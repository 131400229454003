


































import { Component, Vue, Prop } from "vue-property-decorator";
import DateTime from "@/view/content/DateTime.vue";
import BidResultPlotView from "./BidResultPlotView.vue";

import { BidResultVm } from "@/api/api";

@Component({
  components: {
    DateTime,
    BidResultPlotView,
  },
})
export default class BidIndicatorResultView extends Vue {
  @Prop({ required: true })
  bidIndicatorResult!: BidResultVm;

  @Prop({ required: true })
  studyName!: string;

  @Prop({ required: true })
  index!: number;

  get hasNorm() {
    if (this.bidIndicatorResult == null) return false;
    return (
      this.bidIndicatorResult.current?.normMin != null ||
      this.bidIndicatorResult.current?.normMax != null
    );
  }

  get hasPrevValue(): boolean {
    if (this.bidIndicatorResult == null) return false;
    return this.bidIndicatorResult.prev != null;
  }
}
