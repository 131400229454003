var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{key:'row' + _vm.value.current.bidValueId,class:{ 'result-alert': _vm.hasAlert }},[_c('td',[_vm._v(" "+_vm._s(_vm.value.current.indicatorName)+" ")]),_c('td',{staticClass:"d-none d-sm-table-cell font-size-lg"},[_vm._v(" "+_vm._s(_vm.prevValue)+" ")]),_c('td',{staticClass:"px-0",staticStyle:{"max-width":"45px"}},[_c('span',{staticClass:"font-size-lg",class:{
        'text-primary': _vm.value.current.status == _vm.NormStatus.Ok,
        'text-danger': _vm.hasAlert,
      }},[_vm._v(" "+_vm._s(_vm.currentValue)+" "),_c('span',{class:{
          'text-primary': _vm.value.current.status == _vm.NormStatus.Ok,
          'text-danger': _vm.hasAlert,
        },staticStyle:{"line-height":"1.1","vertical-align":"text-top"}},[(_vm.value.current.status === _vm.NormStatus.More)?[_vm._v("↑")]:_vm._e(),(_vm.value.current.status === _vm.NormStatus.Less)?[_vm._v("↓")]:_vm._e()],2)])]),_c('td',{staticClass:"d-none d-sm-table-cell"},[_vm._v(_vm._s(_vm.normMin)+" - "+_vm._s(_vm.normMax))]),_c('td',{staticClass:"text-right pl-0"},[(
        !_vm.hasAvailableDimensions ||
        (_vm.value.current.type != _vm.StudyIndicatorFieldType.Number &&
          _vm.value.current.type != _vm.StudyIndicatorFieldType.Calculate)
      )?[_vm._v(" "+_vm._s(_vm.value.current.dimension)+" ")]:[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedDimension),expression:"selectedDimension"}],staticClass:"dimension-selector",class:{
          'text-warning': _vm.hasAvailableDimensions,
          'text-muted': !_vm.hasAvailableDimensions,
        },staticStyle:{"font-size":"14px"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedDimension=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.value.current.availableDimensions),function(dimension){return _c('option',{key:dimension.name,staticClass:"text-dark",domProps:{"value":dimension}},[_vm._v(" "+_vm._s(dimension.name)+" ")])}),0)]],2)])}
var staticRenderFns = []

export { render, staticRenderFns }