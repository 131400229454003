




























import { Component, Vue, Prop } from "vue-property-decorator";
import BidResultTableView from "./BidResultTableView.vue";
import DateTime from "@/view/content/DateTime.vue";

import { NormStatus, BidVm, BidStatuses } from "@/api/api";

@Component({
  components: {
    DateTime,
    BidResultTableView,
  },
})
export default class BidsResultTableView extends Vue {
  @Prop({ required: true })
  bids!: BidVm[];

  get anyAlert() {
    return this.bids.some(b => b.results?.some(v =>  v.current?.status !== NormStatus.None &&
            v.current?.status !== NormStatus.Ok));
  }

  get anyReady() {
    return this.bids.some((b) => b.status == BidStatuses.Completed);
  }

  get anyPrevResult() {
      return this.bids.some(b => b.results?.some(v =>   v.prev?.value != null ||
            v.prev?.valueStr != null ||
            v.prev?.enumValues != null));
  }
}
