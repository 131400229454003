











import { Component, Vue, Prop } from "vue-property-decorator";
import BidResultTableRowsView from "./BidResultTableRowsView.vue";

import { BidResultVm, BidVm } from "@/api/api";

@Component({
  components: {
    BidResultTableRowsView,
  },
})
export default class BidResultTableView extends Vue {
  @Prop({ required: true })
  bid!: BidVm;

  get bidResults() {
    return this.bid.results || [];
  }

  get indicatorGroups() {
    if (this.bidResults == null) return [];
    return [
      ...new Set(this.bidResults.map((x) => x.current?.indicatorGroupName)),
    ];
  }

  groupIndicators(group: string | null): BidResultVm[] {
    return this.bidResults.filter(
      (x) => x.current?.indicatorGroupName === group
    );
  }
}
